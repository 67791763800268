import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, fromEvent, Observable } from 'rxjs';
import { WINDOW } from '@inshared/shared/util';

@Injectable({
    providedIn: 'root'
})
export class EventListenerService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
    ) {}

    public documentEvent<T>(eventName: string): Observable<T | never> {
        return this.targetEvent<T>(this.document, eventName);
    }

    public windowEvent<T>(eventName: string): Observable<T | never> {
        return this.targetEvent<T>(this.window, eventName);
    }

    public targetEvent<T>(eventTarget: any, eventName: string): Observable<T | never> {
        if (isPlatformServer(this.platformId)) {
            return EMPTY;
        }
        return fromEvent<T>(eventTarget, eventName);
    }
}
